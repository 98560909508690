<template>
  <div class="app-container v" style="background-color: #f8f8f9;">
    <overview />
  </div>
</template>

<script>
import Overview from "./dashboard/overview";

export default {
  components: { Overview }
};
</script>